<template>
  <div class="data-nft-orders-container">
    <p v-if="targetOrders.length > 0" class="top-profit-tip">
      <van-tag plain class="nft-worker-item" type="success"
        >&nbsp;&nbsp;&nbsp;&nbsp;</van-tag
      >&nbsp;&nbsp;代表该材料提交到这个订单利润最高
    </p>
    <p v-if="targetOrders.length === 0">无系统订单</p>
    <div
      class="data-nft-orders-wrap"
      v-for="targetOrder of targetOrders"
      :key="`${sc.collection}-${targetOrder.id}`"
    >
      <div class="nft-top">
        <van-tag
          plain
          class="nft-worker-item"
          :type="
            tdfsnft
              .tcoinPriceInfo(targetOrder.id)
              .minKeys.indexOf(asset.split(' ')[1]) !== -1
              ? 'primary'
              : tdfsnft
                  .tcoinPriceInfo(targetOrder.id)
                  .maxKeys.indexOf(asset.split(' ')[1]) !== -1
              ? 'success'
              : 'primary'
          "
          v-for="asset of targetOrder.require_asset"
          :key="`${sc.collection}-${asset}`"
          ><img class="nft-worker-asset" :src="getAssetPng(asset)" />
          <p class="nft-get-count">
            {{ "" + parseFloat(asset).toFixed(0) }}
          </p></van-tag
        ><van-icon name="/right-arrow.png" class="nft-worker-arrow" />
        <van-tag plain class="nft-worker-item" type="primary"
          ><img class="nft-worker-asset" :src="getAssetPng(targetOrder.pay)" />
          <p class="nft-get-count">
            {{ "" + parseFloat(targetOrder.pay).toFixed(2) }}
          </p>
        </van-tag>
        <!-- TODO 订单附加许愿池宝石  -->
        <van-tag plain class="nft-worker-item" type="primary">
          <img
            class="nft-worker-asset"
            src="https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/AMTHYST.png"
            data-v-bc1abe54=""
          />
          <p class="nft-get-count">
            1
          </p>
        </van-tag>

        {{
          tdfsnft.tcoinPriceInfo(targetOrder.id).max
            ? "🌟"
            : tdfsnft.tcoinPriceInfo(targetOrder.id).min
            ? "👹"
            : ""
        }}
      </div>
      <div class="nft-bottom">
        <!-- TODO 订单附加许愿池宝石  -->
        <p class="nft-profit">
          自产利润: {{
            tdfsnft.tcoinPriceProfit(targetOrder).toFixed(2)
          }}&nbsp;&nbsp;&nbsp;利润率: {{
            (
              (tdfsnft.tcoinPriceProfit(targetOrder) /
                (parseFloat(targetOrder.pay) +
                  0 -
                  tdfsnft.tcoinPriceProfit(targetOrder))) *
              100
            ).toFixed(2)
          }}%
        </p>
        <template
          v-if="
            tdfsnft.tcoinPriceDetail(targetOrder) &&
              tdfsnft.tcoinPriceDetail(targetOrder).length >= 3
          "
        >
          <p class="nft-profit">
            {{ tdfsnft.tcoinPriceDetail(targetOrder)[1] }}
          </p>
          <p class="nft-profit">
            {{ tdfsnft.tcoinPriceDetail(targetOrder)[0] }}
          </p>
          <p class="nft-profit">
            {{ tdfsnft.tcoinPriceDetail(targetOrder)[2] }}
          </p>
          <!-- <p class="nft-profit">
            {{ tdfsnft.tcoinPriceDetail(targetOrder)[3] }}
          </p> -->
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import dfsnft from "@/utils/dfsnft";
export default {
  name: "DataNFTOrders",
  props: {
    sc: null,
    orders: null,
    workers: null,
  },
  data() {
    return {
      tdfsnft: dfsnft,
    };
  },
  watch: {
    now() {},
  },
  computed: {
    ...mapState({
      accountName: (state) => state.app.accountName,
      farmCrops: (state) => state.app.farmCrops,
      levelup: (state) => state.app.levelup,
    }),
    targetLevelUp() {
      return this.levelup.filter((v) => {
        return v.collection === this.sc.collection;
      })[0];
    },
    targetOrders() {
      if (!this.workers || !this.orders) {
        return [];
      }
      var t = [];
      for (let worker of this.workers) {
        if (worker.require_nft_type === this.sc.collection) {
          t.push(worker.create_asset.split(" ")[1]);
        }
      }
      var result = [];
      for (let order of this.orders) {
        for (let asset of order.require_asset) {
          if (t.indexOf(asset.split(" ")[1]) != -1) {
            if (
              result
                .flatMap((v) => {
                  return v.id;
                })
                .indexOf(order.id) === -1
            ) {
              result.push(order);
            }
            continue;
          }
        }
      }
      return result;
    },
  },
  methods: {
    moment,
    getAssetPng(asset) {
      let a = asset.split(" ")[1];
      for (let crop of this.farmCrops) {
        if (crop.key === a) {
          return crop.url;
        }
      }
      return "";
    },
  },
};
</script>

<style scoped lang="scss">
.data-nft-orders-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 8px;
  p {
    font-size: 12px;
  }
  .top-profit-tip {
    font-size: 11px;
    color: #888888;
  }
  .data-nft-orders-wrap {
    width: 100%;
  }
  .nft-top {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    margin-top: 8px;
    flex-wrap: wrap;
    .nft-img {
      width: 30px;
      margin-right: 6px;
    }
    .nft-level {
      margin-right: 6px;
    }
    .nft-get-time {
      font-size: 13px;
      margin-left: 10px;
      font-weight: bold;
    }
    p {
      font-size: 13px;
    }
    .nft-get-count {
      font-weight: bold;
    }
    img {
      margin: 0 2px;
      width: 30px;
    }
    .nft-workers-tip {
      font-size: 12px;
      margin-bottom: 4px;
    }
    .nft-worker-arrow {
      margin: 0 4px;
    }
    .nft-worker-item {
      margin-right: 4px;
    }
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    .nft-worker-asset {
      width: 20px;
      padding: 2px 0;
    }
    .nft-get-time {
      font-size: 13px;
      margin-left: 10px;
      font-weight: bold;
    }
    .nft-profit {
      font-size: 11px;
      color: #888888;
    }
  }
  .nft-bottom {
    .nft-profit {
      font-size: 11px;
      color: #888888;
    }
  }
}
</style>
