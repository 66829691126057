<template>
  <div class="shop-container">
    <van-tabs
      sticky
      class="shop-tabs"
      :active="active"
      color="#1989fa"
      border
      :before-change="beforeChange"
    >
      <template v-for="fc of farmCrops" :key="fc.key">
        <van-tab v-if="fc.name"
          ><template #title
            ><div class="shop-tab">
              <img :src="fc.url" />{{ fc.name }}
            </div></template
          >
          <!-- <van-pull-refresh v-model="loading" @refresh="reloadData(fc.key)"> -->
          <div>
            <div class="top-actions">
              <van-button
                @click="reloadData(fc.key)"
                class="buy"
                size="small"
                type="primary"
                >&nbsp;&nbsp;刷新&nbsp;&nbsp;</van-button
              >
              <p class="tip">
                <span v-if="dcashPrice > 0"
                  >1DCASH={{ dcashPrice.toFixed(4) }}USDT<br
                /></span>
              </p>
            </div>
            <p
              class="shop-first-price"
              v-if="
                this.$store.getters.app.workerPrices &&
                  this.$store.getters.app.workerPrices[fc.key]
              "
            >
              成本价:{{
                this.$store.getters.app.workerPrices[fc.key].toFixed(4)
              }}&nbsp;DCASH&nbsp;&nbsp;&nbsp;&nbsp;{{
                accountName && accountName !== ""
                  ? `你的${fc.name}库存: ${stock}`
                  : ``
              }}
              <template
                v-if="
                  ordersFlatMap.indexOf(fc.key) !== -1 &&
                    this.$store.getters.app.tcoinPrices[fc.key] &&
                    this.$store.getters.app.tcoinMaxPrices[fc.key]
                "
                ><br />系统回收最低价:{{
                  this.$store.getters.app.tcoinPrices[fc.key].toFixed(4)
                }}&nbsp;DCASH&nbsp;&nbsp;&nbsp;<br />系统回收最高价:{{
                  this.$store.getters.app.tcoinMaxPrices[fc.key].toFixed(4)
                }}&nbsp;DCASH</template
              >
            </p>
            <van-loading size="24px" vertical color="#0094ff" v-if="loading">
              加载中...</van-loading
            >
            <div>
              <div class="shop-actions">
                <van-button
                  v-if="accountName !== ''"
                  size="mini"
                  type="danger"
                  @click="sale"
                  >发布出售</van-button
                ><van-button
                  v-if="accountName !== ''"
                  size="mini"
                  type="success"
                  @click="buy"
                  >发布采购</van-button
                >
              </div>
              <div class="shop-nfts">
                <van-tag mark type="primary"
                  >市场出售订单(最低价格的10条订单)</van-tag
                >
                <shop-sell-orders
                  :sellOrders="sellOrders[fc.key]"
                  v-if="sellOrders[fc.key] && sellOrders[fc.key].length > 0"
                  @reload="reloadData(fc.key)"
                />
                <p class="empty-p" v-else>无数据</p>
              </div>
              <div class="shop-nfts">
                <van-tag mark type="primary"
                  >市场采购订单(最高价格的10条订单)</van-tag
                >
                <shop-buy-orders
                  :buyOrders="buyOrders[fc.key]"
                  v-if="buyOrders[fc.key] && buyOrders[fc.key].length > 0"
                  @reload="reloadData(fc.key)"
                />
                <p class="empty-p" v-else>无数据</p>
              </div>
            </div>
          </div>
          <!-- </van-pull-refresh> -->
        </van-tab>
      </template> </van-tabs
    ><dialog-sale-coin
      :coins="coins"
      :dialogShow="dialogShow"
      @hidden="dialogShow = false"
      @reload="reloadData(this.farmCrops[this.active + 1].key)"
    />
    <dialog-buy-coin
      :coins="coins"
      :dialogShowBuy="dialogShowBuy"
      @hidden="dialogShowBuy = false"
      @reload="reloadData(this.farmCrops[this.active + 1].key)"
    />
  </div>
</template>

<script>
import dfsnft from "@/utils/dfsnft.js";
import { mapState } from "vuex";
import ShopSellOrders from "@/components/ShopSellOrders";
import ShopBuyOrders from "@/components/ShopBuyOrders";
import DialogSaleCoin from "@/components/DialogSaleCoin";
import DialogBuyCoin from "@/components/DialogBuyCoin";
export default {
  name: "ShopData",
  components: { ShopSellOrders, ShopBuyOrders, DialogSaleCoin, DialogBuyCoin },
  data() {
    return {
      active: 0,
      loading: null,
      sellOrders: {},
      buyOrders: {},
      coins: [],
      dialogShow: false,
      dialogShowBuy: false,
    };
  },
  watch: {},
  computed: {
    ...mapState({
      orders: (state) => state.app.orders,
      dcashPrice: (state) => state.app.dcashPrice,
      farmCrops: (state) => state.app.farmCrops,
      shopPngPre: (state) => state.app.shopPngPre,
      shopConf: (state) => state.app.shopConf,
      gemfactoryWorks: (state) => state.app.gemfactoryWorks,
      accountName: (state) => state.app.accountName,
    }),
    ordersFlatMap() {
      return this.orders.flatMap((v) => {
        return v.require_asset.map((v2) => {
          return v2.split(" ")[1];
        });
      });
    },
    shopConfCollections() {
      return this.shopConf.flatMap((v) => {
        return v.collection;
      });
    },
    stock() {
      for (let coin of this.coins) {
        if (coin && coin.balance) {
          if (
            coin.balance.split(" ")[1] === this.farmCrops[this.active + 1].key
          ) {
            return parseFloat(coin.balance);
          }
        }
      }
      return 0;
    },
  },
  activated() {
    let that = this;
    setTimeout(() => {
      if (!that.loading) {
        that.reloadData(that.farmCrops[that.active + 1].key);
      }
    }, 300);
  },
  mounted() {
    let that = this;
    setTimeout(() => {
      that.active = localStorage.getItem("Shop-Active")
        ? parseInt(localStorage.getItem("Shop-Active"))
        : 0;
      if (!that.loading) {
        that.reloadData(that.farmCrops[that.active + 1].key);
      }
    }, 100);
  },
  unmounted() {},
  methods: {
    sale() {
      this.dialogShow = true;
    },
    buy() {
      this.dialogShowBuy = true;
    },
    beforeChange(index) {
      this.active = index;
      localStorage.setItem("Shop-Active", this.active);
      this.reloadData(this.farmCrops[index + 1].key);
      return true;
    },
    getTargetNftAsset(sc) {
      if (this.nftAssets) {
        for (let nftAsset of this.nftAssets) {
          if (nftAsset.collection === sc.collection) {
            return nftAsset;
          }
        }
      }
      return null;
    },
    async reloadData(asset) {
      console.log("reloadData");
      console.log(asset);
      this.loading = true;
      var res = await dfsnft.getSellOrders(asset);
      this.sellOrders[asset] = res && res.rows ? res.rows : [];
      this.sellOrders[asset] = this.sellOrders[asset]
        .sort((a, b) => {
          return (
            parseFloat(a.cash) / parseFloat(a.item) -
            parseFloat(b.cash) / parseFloat(b.item)
          );
        })
        .slice(0, 10);
      res = await dfsnft.getBuyOrders(asset);
      this.buyOrders[asset] = res && res.rows ? res.rows : [];
      this.buyOrders[asset] = this.buyOrders[asset]
        .sort((a, b) => {
          return (
            parseFloat(b.cash) / parseFloat(b.item) -
            parseFloat(a.cash) / parseFloat(a.item)
          );
        })
        .slice(0, 10);
      if (this.accountName && this.accountName !== "") {
        res = await dfsnft.getCoins(this.accountName);
        this.coins = res && res.rows ? res.rows : [];
      }
      this.loading = false;
      await dfsnft.getWorker();
      await dfsnft.getOrders();
    },
  },
};
</script>
<style lang="scss" scoped>
.shop-container {
  width: 100%;
  //   overflow-y: auto;
  //   position: absolute;
  //   top: 0;
  .shop-tabs {
    .shop-tab {
      display: flex;
      align-items: center;
    }
    img {
      width: 30px;
      margin-right: 4px;
    }
    .tip {
      display: inline-block;
      margin-left: 10px;
      font-size: 11px;
      color: #949494;
      span {
        font-weight: normal;
      }
    }
    .shop-first-price {
      font-size: 11px;
      margin: 8px 10px 0 10px;
      color: #949494;
    }
    .top-actions {
      margin-top: 10px;
      display: flex;
      align-items: center;
    }
    .shop-nfts-info {
      margin: 10px;
      border-top: 4px solid #eee;
      border-left: 1px solid #eee;
      border-right: 1px solid #eee;
      border-bottom: 1px solid #eee;
      background-color: #fff;
      padding: 10px;
      p {
        display: flex;
        align-items: center;
        font-size: 13px;
        span {
          font-weight: bold;
        }
        .nft-worker-asset {
          width: 20px;
        }
      }
    }
    .empty-p {
      font-size: 13px;
      margin: 4px 0;
    }
    .buy {
      margin: 0px 0px 0 10px;
      // width: calc(100% - 20px);
    }
    .shop-actions {
      margin: 4px 10px 0px 10px;
    }
    .shop-nfts {
      margin: 10px;
      border-top: 4px solid #eee;
      border-left: 1px solid #eee;
      border-right: 1px solid #eee;
      border-bottom: 1px solid #eee;
      background-color: #fff;
      padding: 10px;
      padding-bottom: 0;
    }
  }
}
</style>
