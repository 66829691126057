<template>
  <div v-if="nft" class="SimulationNFT-container">
    <div class="nft-top">
      <img class="nft-img" :src="nft.png" />
      <p class="nft-name">{{ nft.name }}</p>
    </div>
    <div class="nft-bottom">
      <div class="nft-bottom-wrap">
        <p>等级:</p>
        <van-stepper v-model="llevel" min="1" max="99" button-size="20" />
      </div>
      <div class="nft-bottom-wrap">
        <p>选择生产物品:</p>
        <van-radio-group v-model="ttype" direction="horizontal">
          <van-radio
            v-for="ca of columns"
            :key="ca.key"
            icon-size="14"
            :name="ca.key"
            ><div class="nft-create-asset">
              <p>{{ ca.name }}</p>
              <img :src="tdfsnft.getAssetPng(null, `1.0000 ${ca.key}`)" /></div
          ></van-radio>
        </van-radio-group>
      </div>
    </div>
    <div class="nft-bottom" v-if="requireAsset && requireAsset.length > 0">
      <div class="nft-workers">
        <div class="nft-worker">
          <van-tag
            plain
            class="nft-worker-item"
            type="primary"
            v-for="asset of requireAsset"
            :key="`${asset}`"
            ><p v-if="asset.indexOf(' ') === -1">{{ llevel }}USDT</p>
            <img
              v-if="asset.indexOf(' ') != -1"
              class="nft-worker-asset"
              :src="tdfsnft.getAssetPng(store, asset)"
            />
            <p v-if="asset.indexOf(' ') != -1" class="nft-get-count">
              {{ (parseFloat(asset) * llevel).toFixed(0) }}
            </p>
            <img
              class="nft-worker-asset"
              :src="asset"
              v-if="asset.indexOf(' ') == -1"
            />
          </van-tag>
          <van-icon name="/right-arrow.png" class="nft-worker-arrow" />
          <van-tag plain type="primary" v-if="createAsset.indexOf(' ') != -1"
            ><img
              class="nft-worker-asset"
              :src="tdfsnft.getAssetPng(store, createAsset)"
            />
            <p class="nft-get-count">
              {{ (parseFloat(createAsset) * llevel).toFixed(0) }}
            </p></van-tag
          >
          <van-tag plain type="primary" v-else
            ><img
              class="nft-worker-asset"
              :src="tdfsnft.getAssetPng(store, createAsset)"
            />
            <p class="nft-get-count">
              {{ (parseFloat(createAsset) * llevel).toFixed(0) }}
            </p></van-tag
          >
          <p class="nft-get-time">
            <van-icon name="clock-o" />
            {{ requireTime / 60 / 60 }}小时
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import dfsnft from "@/utils/dfsnft";
export default {
  name: "SimulationNFT",
  emits: ["change"],
  components: {},
  props: {
    nft: null,
    index: null,
  },
  data() {
    return {
      show: false,
      llevel: 1,
      ttype: "",
      tdfsnft: dfsnft,
      store: this.store,
    };
  },
  watch: {
    now() {},
    llevel() {
      this.$emit("change", {
        task: this.task,
        index: this.index,
        level: this.llevel,
        type: this.ttype,
      });
    },
    ttype() {
      this.$emit("change", {
        task: this.task,
        index: this.index,
        level: this.llevel,
        type: this.ttype,
      });
    },
  },
  computed: {
    task() {
      if (this.ttype) {
        return this.columns.filter((v) => {
          return v.key === this.ttype;
        })[0];
      }
      return null;
    },
    requireAsset() {
      if (this.ttype) {
        return this.columns.filter((v) => {
          return v.key === this.ttype;
        })[0].require_asset;
      }
      return [];
    },
    createAsset() {
      if (this.ttype) {
        return this.columns.filter((v) => {
          return v.key === this.ttype;
        })[0].create_asset;
      }
      return [];
    },
    requireTime() {
      if (this.ttype) {
        return this.columns.filter((v) => {
          return v.key === this.ttype;
        })[0].require_time;
      }
      return [];
    },
    columns() {
      if (this.nft) {
        let v = this.workers
          .filter((v) => {
            return v.require_nft_type === this.nft.collection;
          })
          .filter((v) => {
            return v.require_level <= this.llevel;
          })
          .map((v) => {
            v.name = this.farmCrops.filter((v2) => {
              return v2.key === v.create_asset.split(" ")[1];
            })[0].name;
            v.key = v.create_asset.split(" ")[1];
            return v;
          });
        return v;
      }
      return [];
    },
    ...mapState({
      workers: (state) => state.app.workers,
      accountName: (state) => state.app.accountName,
      farmCrops: (state) => state.app.farmCrops,
    }),
    getTaskPng() {
      if (this.nft && this.nft.task) {
        for (let crop of this.farmCrops) {
          if (crop.key === this.nft.task.farm) {
            return crop.url;
          }
        }
      }
      return "";
    },
  },
  methods: {
    moment,
    onConfirm(value) {
      this.show = false;
      this.nfts.push(
        this.shopConf
          .filter((v) => {
            return v.name === value;
          })
          .map((v) => {
            v.png = this.shopPngPre + v.collection + ".png";
            return v;
          })[0]
      );
    },
    onCancel() {
      this.show = false;
    },
  },
};
</script>

<style scoped lang="scss">
.SimulationNFT-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 8px;
  .nft-top {
    // width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    .nft-name {
      font-size: 12px;
      margin-right: 6px;
    }
    .nft-img {
      width: 30px;
      margin-right: 6px;
    }
    .nft-level {
      margin-right: 6px;
    }
    .nft-level,
    .nft-task {
      font-size: 11px;
    }
    .nft-get-time {
      font-size: 13px;
      margin-left: 10px;
      font-weight: bold;
    }
  }
  .nft-bottom {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    ::v-deep .van-cell {
      padding: 0;
      p {
        font-size: 12px;
      }
    }
    p {
      font-size: 13px;
    }
    .nft-bottom-wrap {
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: 8px;
      p {
        flex-shrink: 0;
        margin-right: 10px;
      }
    }
    .nft-create-asset {
      display: flex;
      align-items: center;
      img {
        width: 22px;
      }
      p {
        margin-right: 2px;
      }
    }
    .nft-workers {
      width: 100%;
      .nft-worker {
        .nft-worker-arrow {
          margin: 0 4px;
        }
        .nft-worker-item {
          margin-right: 4px;
        }
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        .nft-worker-asset {
          width: 20px;
          padding: 2px 0;
        }
        .nft-get-time {
          font-size: 13px;
          margin-left: 10px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
