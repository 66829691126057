<template>
  <div class="data-container">
    <van-tabs
      sticky
      class="data-tabs"
      :active="active"
      color="#1989fa"
      border
      :before-change="beforeChange"
    >
      <van-tab v-for="sc of shopConf" :key="sc.collection"
        ><template #title
          ><div class="data-tab">
            <img :src="this.shopPngPre + sc.collection + '.png'" />{{ sc.name }}
          </div></template
        >
        <div>
          <div class="data-nfts-info" v-if="getTargetNftAsset(sc)">
            <p>
              已生成的{{ sc.name }}NFT数量:
              <span>{{ getTargetNftAsset(sc).supply }}</span>
            </p>
            <p>
              一级{{ sc.name }}商店售价:
              <img
                class="nft-worker-asset"
                src="https://cdn.jsdelivr.net/gh/defis-net/material3/Town-img/farm/dcash.png"
                data-v-74bc2eb2=""
              /><span>{{
                (100 + getTargetNftAsset(sc).supply / 10).toFixed(2)
              }}</span>
            </p>
            <p>
              <span class="tip" v-if="dcashPrice > 0"
                >1DCASH={{ dcashPrice.toFixed(4) }}USDT</span
              >
            </p>
          </div>
          <div class="data-nfts">
            <van-tag mark type="primary">可生产的任务</van-tag>
            <data-NFT :sc="sc" :workers="workers" />
          </div>
          <div class="data-nfts">
            <van-tag mark type="primary">升级说明</van-tag>
            <data-NFT-level-up :sc="sc" />
          </div>
          <div class="data-nfts">
            <div class="data-nfts-top">
              <van-tag mark type="primary">系统订单</van-tag>
              <p>🌟最佳订单 👹最差订单</p>
            </div>
            <data-NFT-orders :sc="sc" :orders="orders" :workers="workers" />
          </div></div
      ></van-tab>
    </van-tabs>
  </div>
</template>

<script>
import moment from "moment";
import dfsnft from "@/utils/dfsnft.js";
import DataNFT from "@/components/DataNFT";
import DataNFTLevelUp from "@/components/DataNFTLevelUp";
import DataNFTOrders from "@/components/DataNFTOrders";
import { mapState } from "vuex";
export default {
  name: "NFTData",
  components: { DataNFT, DataNFTLevelUp, DataNFTOrders },
  data() {
    return {
      nfts: null,
      workers: null,
      loading: null,
      coins: null,
      now: moment(),
      timer: null,
      account: "",
      orders: null,
      nftAssets: null,
      active: 0,
    };
  },
  watch: {},
  activated() {
    let that = this;
    setTimeout(() => {
      if (!that.loading) {
        that.reloadData();
      }
    }, 300);
  },
  computed: {
    ...mapState({
      dcashPrice: (state) => state.app.dcashPrice,
      shopPngPre: (state) => state.app.shopPngPre,
      shopConf: (state) => state.app.shopConf,
      gemfactoryWorks: (state) => state.app.gemfactoryWorks,
    }),
    shopConfCollections() {
      return this.shopConf.flatMap((v) => {
        return v.collection;
      });
    },
  },
  mounted() {
    let that = this;
    setTimeout(() => {
      that.active = localStorage.getItem("Game-Active")
        ? parseInt(localStorage.getItem("Game-Active"))
        : 0;
      if (!that.loading) {
        that.reloadData();
      }
    }, 100);
  },
  unmounted() {},
  methods: {
    beforeChange(index) {
      this.active = index;
      localStorage.setItem("Game-Active", this.active);
      this.reloadData();
      return true;
    },
    getTargetNftAsset(sc) {
      if (this.nftAssets) {
        for (let nftAsset of this.nftAssets) {
          if (nftAsset.collection === sc.collection) {
            return nftAsset;
          }
        }
      }
      return null;
    },
    async reloadData() {
      this.loading = true;
      var res = await dfsnft.getWorker();
      this.workers = res && res.rows ? res.rows : null;
      if (this.workers) {
        this.workers = this.workers.concat(this.gemfactoryWorks);
      }
      res = await dfsnft.getOrders();
      this.orders = res && res.rows ? res.rows : null;
      res = await dfsnft.getNftAssets();
      this.nftAssets = res && res.rows ? res.rows : null;
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.data-container {
  width: 100%;
  //   height: calc(100vh - 50px);
  //   overflow-y: auto;
  //   position: absolute;
  //   top: 0;
  .data-tabs {
    .data-tab {
      display: flex;
      align-items: center;
    }
    img {
      width: 30px;
      margin-right: 4px;
    }
    .data-nfts-info {
      margin: 10px;
      border-top: 4px solid #eee;
      border-left: 1px solid #eee;
      border-right: 1px solid #eee;
      border-bottom: 1px solid #eee;
      background-color: #fff;
      padding: 10px;
      p {
        display: flex;
        align-items: center;
        font-size: 13px;
        span {
          font-weight: bold;
        }
        .nft-worker-asset {
          width: 20px;
        }
      }
      .tip {
        margin-top: 3px;
        font-size: 11px;
        color: #949494;
        span {
          font-weight: normal;
        }
      }
    }
    .data-nfts {
      margin: 10px;
      border-top: 4px solid #eee;
      border-left: 1px solid #eee;
      border-right: 1px solid #eee;
      border-bottom: 1px solid #eee;
      background-color: #fff;
      padding: 10px;
      padding-bottom: 0;
      .data-nfts-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
