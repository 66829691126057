<template>
  <div v-if="sellOrders" class="shop-sellorders-container">
    <div
      class="sellorders-wrap"
      v-for="sellOrder of sellOrders"
      :key="sellOrder.oid"
    >
      <div class="nft-count">
        <img :src="dfsnft.getAssetPng(this.$store, sellOrder.item)" />
        <p class="nft-get-count">
          &nbsp;{{ parseFloat(sellOrder.item).toFixed(0) }}
        </p>
        <!-- <p class="nft-owner">{{ sellOrder.owner }}</p> -->
      </div>
      <!-- <div class="nft-account">123123</div> -->
      <div class="nft-price">
        单价:<img
          class="nft-worker-asset"
          src="https://cdn.jsdelivr.net/gh/defis-net/material3/Town-img/farm/dcash.png"
          data-v-74bc2eb2=""
        /><span
          >{{
            (parseFloat(sellOrder.cash) / parseFloat(sellOrder.item)).toFixed(4)
          }}
        </span>
      </div>
      <div class="nft-price-total">
        总售价:<van-tag
          v-if="accountName !== '' && accountName !== sellOrder.owner"
          type="primary"
          class="nft-task"
          @click="clickOnBuy(sellOrder)"
          ><img
            class="nft-worker-asset"
            src="https://cdn.jsdelivr.net/gh/defis-net/material3/Town-img/farm/dcash.png"
            data-v-74bc2eb2=""
          />{{ parseFloat(sellOrder.cash).toFixed(4) }}</van-tag
        >
        <template v-else>
          <img
            class="nft-worker-asset"
            src="https://cdn.jsdelivr.net/gh/defis-net/material3/Town-img/farm/dcash.png"
            data-v-74bc2eb2=""
          />{{ parseFloat(sellOrder.cash).toFixed(4) }}</template
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import dfsnft from "@/utils/dfsnft";
import scatter from "@/utils/scatter";
import { Toast, Dialog } from "vant";

export default {
  emits: ["reload"],
  name: "ShopSellOrders",
  props: {
    sellOrders: null,
  },
  data() {
    return {
      dfsnft,
    };
  },
  watch: {},
  computed: {
    ...mapState({
      accountName: (state) => state.app.accountName,
      farmCrops: (state) => state.app.farmCrops,
    }),
  },
  methods: {
    moment,
    clickOnBuy(sellOrder) {
      let that = this;
      let name = this.farmCrops.filter((v) => {
        return v.key === sellOrder.item.split(" ")[1];
      })[0].name;
      Dialog.confirm({
        title: "提示",
        message: `确定以单价【${(
          parseFloat(sellOrder.cash) / parseFloat(sellOrder.item)
        ).toFixed(4)}】购买${parseFloat(sellOrder.item).toFixed(0)}个${name}？`,
      })
        .then(() => {
          scatter.orderSell(
            { quantity: sellOrder.cash, orderid: sellOrder.oid },
            (e) => {
              if (scatter.hcb(e)) {
                Toast.success("购买成功");
                that.$emit("reload");
              }
            }
          );
        })
        .catch(() => {
          // on cancel
        });
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.shop-sellorders-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  flex-wrap: wrap;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 8px;
  .sellorders-wrap {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  img {
    width: 24px;
  }
  .nft-get-count {
    font-size: 11px;
    font-weight: bold;
  }
  .nft-owner {
    font-size: 11px;
    margin-left: 5px;
  }
  .nft-price {
    margin-left: 8px;
    font-size: 11px;
    display: flex;
    align-items: center;
    span {
      font-weight: bold;
    }
    img {
      width: 20px;
    }
  }
  .nft-count {
    display: flex;
    align-items: center;
  }
  .nft-price-total {
    margin-left: 8px;
    font-size: 11px;
    display: flex;
    align-items: center;
    img {
      width: 20px;
    }
  }
}
</style>
