<template>
  <div class="other-container">
    <van-cell-group title="风车小镇助手">
      <van-cell
        title="风车小镇官网"
        is-link
        url="https://dfsfarms.netlify.app/"
      />
      <van-cell
        is-link
        title="EOS节点"
        :value="eosnode.name"
        @click="
          () => {
            showPicker = true;
          }
        "
      />
      <van-cell title="免CPU操作"
        ><van-switch v-model="checked" size="20px" />
      </van-cell>
      <van-cell title="助手版本号" value="v0.2.4" />
    </van-cell-group>
    <!-- <van-cell-group title="更新说明">
      <van-cell title="v0.2.3" value="添加一键收获" />
      <van-cell title="v0.2.2" value="添加采购操作" />
      <van-cell title="v0.2.1" value="可在助手中进行物品回收操作" />
      <van-cell title="v0.2.0" value="可在助手中进行小镇的交互操作" />
      <van-cell title="v0.1.4" value="添加价值、利润预估" />
      <van-cell title="v0.1.3" value="添加DCASH价格信息、添加NFT总价值计算" />
      <van-cell title="v0.1.2" value="新增市场信息" />
      <van-cell title="v0.1.1" value="首页信息新增账号出售、采购订单信息" />
      <van-cell title="v0.1.0" value="新增首页信息、生产关系页" />
    </van-cell-group> -->
  </div>
  <van-popup :show="showPicker" round position="bottom">
    <van-picker
      title="选择节点"
      show-toolbar
      :columns="columns"
      @confirm="onConfirm"
      @cancel="onCancel"
  /></van-popup>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "NFTOther",
  components: {},
  data() {
    return {
      showPicker: false,
      checked: this.freecpu,
    };
  },
  watch: {
    checked() {
      this.$store.dispatch("setFreecpu", this.checked);
    },
  },
  computed: {
    ...mapState({
      eosnode: (state) => state.app.eosnode,
      nodes: (state) => state.app.nodes,
      freecpu: (state) => state.app.freecpu,
    }),
    columns() {
      return this.nodes.flatMap((v) => {
        return v.name;
      });
    },
  },
  mounted() {
    this.checked = this.freecpu === "true" || this.freecpu === true;
  },
  unmounted() {},
  methods: {
    onConfirm(value) {
      this.showPicker = false;
      this.$store.dispatch("setEosnode", value);
    },
    onCancel() {
      this.showPicker = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.other-container {
  width: 100%;
}
</style>
