<template>
  <div class="data-nft-level-up-container">
    <div
      class="data-nft-level-wrap"
      v-for="level of [1, 2, 3, 99]"
      :key="level"
    >
      <div class="nft-top">
        <van-tag type="primary" class="nft-level"
          >等级{{ level > 3 ? "n-1" : level }}=>{{
            level > 3 ? "n" : level + 1
          }}</van-tag
        ><van-icon name="/right-arrow.png" class="nft-worker-arrow" />
        <van-tag
          plain
          class="nft-worker-item"
          type="primary"
          v-for="asset of targetLevelUp.genNeeds(level)"
          :key="`${sc.collection}-${asset}`"
          ><img class="nft-worker-asset" :src="getAssetPng(asset)" />
          <p
            v-if="asset.indexOf(' ') != -1 && asset.split(' ')[1] === 'DCASH'"
            class="nft-get-count"
          >
            {{
              level > 3
                ? `${parseFloat(asset) === 1 ? "" : "" + parseFloat(asset)}`
                : "" + parseFloat(asset).toFixed(0)
            }}
          </p>
          <p
            v-if="asset.indexOf(' ') != -1 && asset.split(' ')[1] !== 'DCASH'"
            class="nft-get-count"
          >
            {{
              level > 3
                ? `${parseFloat(asset) === 1 ? "" : "*" + "10+2*(n-2)"}`
                : "" + parseFloat(asset).toFixed(0)
            }}
          </p>
        </van-tag>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "DataNFTLevelUp",
  props: {
    sc: null,
  },
  data() {
    return {};
  },
  watch: {
    now() {},
  },
  computed: {
    ...mapState({
      farmCrops: (state) => state.app.farmCrops,
      levelup: (state) => state.app.levelup,
    }),
    targetLevelUp() {
      return this.levelup.filter((v) => {
        return v.collection === this.sc.collection;
      })[0];
    },
  },
  methods: {
    moment,
    getAssetPng(asset) {
      let a = asset.split(" ")[1];
      for (let crop of this.farmCrops) {
        if (crop.key === a) {
          return crop.url;
        }
      }
      return "";
    },
  },
};
</script>

<style scoped lang="scss">
.data-nft-level-up-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 8px;
  .data-nft-level-wrap {
    width: 100%;
  }
  .nft-top {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    margin-top: 8px;
    .nft-img {
      width: 30px;
      margin-right: 6px;
    }
    .nft-level {
      margin-right: 6px;
    }
    .nft-get-time {
      font-size: 13px;
      margin-left: 10px;
      font-weight: bold;
    }
    p {
      font-size: 13px;
    }
    .nft-get-count {
      font-weight: bold;
    }
    img {
      margin: 0 2px;
      width: 30px;
    }
    .nft-workers-tip {
      font-size: 12px;
      margin-bottom: 4px;
    }
    .nft-worker-arrow {
      margin: 0 2px;
    }
    .nft-worker-item {
      margin-right: 4px;
    }
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    .nft-worker-asset {
      width: 20px;
      padding: 2px 0;
    }
    .nft-get-time {
      font-size: 13px;
      margin-left: 10px;
      font-weight: bold;
    }
  }
}
</style>
