<template>
  <div class="data-nft-container">
    <div
      class="data-nft-wrap"
      v-for="level of sc.collection === 'land'
        ? [1, 2, 3, 4, 5, 99]
        : [1, 2, 3, 99]"
      :key="level"
    >
      <div class="nft-top">
        <van-tag type="primary" class="nft-level"
          >等级:{{ level > maxLevel ? "n" : level }}</van-tag
        >
      </div>
      <div class="nft-bottom">
        <div class="nft-workers">
          <div
            class="nft-worker"
            v-for="worker of levelTargetWorkers(level)"
            :key="worker.id"
          >
            <van-tag
              plain
              class="nft-worker-item"
              type="primary"
              v-for="asset of worker.require_asset"
              :key="`${worker.id}-${asset}`"
              ><p v-if="asset.indexOf(' ') === -1">
                {{ level > maxLevel ? "n" : level }}USDT
              </p>
              <img
                v-if="asset.indexOf(' ') != -1"
                class="nft-worker-asset"
                :src="getAssetPng(asset)"
              />
              <p v-if="asset.indexOf(' ') != -1" class="nft-get-count">
                {{
                  level > maxLevel
                    ? `${
                        parseFloat(asset) === 1 ? "" : " * " + parseFloat(asset)
                      } * n`
                    : "" + (parseFloat(asset) * level).toFixed(0)
                }}
              </p>
              <img
                class="nft-worker-asset"
                :src="asset"
                v-if="asset.indexOf(' ') == -1"
              />
            </van-tag>
            <van-icon name="/right-arrow.png" class="nft-worker-arrow" />
            <van-tag
              plain
              type="primary"
              v-if="worker.create_asset.indexOf(' ') != -1"
              ><img
                class="nft-worker-asset"
                :src="getAssetPng(worker.create_asset)"
              />
              <p class="nft-get-count">
                {{
                  level > maxLevel
                    ? `${
                        parseFloat(worker.create_asset) === 1
                          ? ""
                          : " * " + parseFloat(worker.create_asset)
                      } * n`
                    : "" + (parseFloat(worker.create_asset) * level).toFixed(0)
                }}
              </p></van-tag
            >
            <van-tag plain type="primary" v-else
              ><img
                class="nft-worker-asset"
                :src="getAssetPng(worker.create_asset)"
              />
              <p class="nft-get-count">
                {{
                  level > maxLevel
                    ? `${
                        parseFloat(worker.create_asset) === 1
                          ? ""
                          : " * " + parseFloat(worker.create_asset)
                      } * n`
                    : (parseFloat(worker.create_asset) * level).toFixed(0)
                }}
              </p></van-tag
            >
            <p class="nft-get-time">
              <van-icon name="clock-o" />
              {{
                // eslint-disable-next-line vue/no-parsing-error
                worker.require_time < 3600
                  ? (worker.require_time / 60).toFixed(0) + "分钟"
                  : worker.require_time / 60 / 60 + "小时"
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "DataNFT",
  props: {
    sc: null,
    workers: null,
  },
  data() {
    return {};
  },
  watch: {
    now() {},
  },
  computed: {
    ...mapState({
      farmCrops: (state) => state.app.farmCrops,
    }),
    maxLevel() {
      if (this.sc.collection === "land") {
        return 5;
      }
      return 3;
    },
    targetWorkers() {
      if (this.workers) {
        return this.workers.filter((v) => {
          return v.require_nft_type === this.sc.collection;
        });
      }
      return [];
    },
  },
  methods: {
    moment,
    levelTargetWorkers(level) {
      return this.targetWorkers.filter((v) => {
        return level >= v.require_level;
      });
    },
    getAssetPng(asset) {
      let a = asset.split(" ")[1];
      for (let crop of this.farmCrops) {
        if (crop.key === a) {
          return crop.url;
        }
      }
      return "";
    },
  },
};
</script>

<style scoped lang="scss">
.data-nft-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 8px;
  .data-nft-wrap {
    width: 100%;
  }
  .nft-top {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    margin-top: 8px;
    .nft-img {
      width: 30px;
      margin-right: 6px;
    }
    .nft-level {
      margin-right: 6px;
    }
    .nft-get-time {
      font-size: 13px;
      margin-left: 10px;
      font-weight: bold;
    }
  }
  .nft-bottom {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    p {
      font-size: 13px;
    }
    .nft-get-count {
      font-weight: bold;
    }
    img {
      margin: 0 4px;
      width: 30px;
    }
    .nft-workers-tip {
      font-size: 12px;
      margin-bottom: 4px;
    }
    .nft-workers {
      width: 100%;
      .nft-worker {
        .nft-worker-arrow {
          margin: 0 4px;
        }
        .nft-worker-item {
          margin-right: 4px;
        }
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        .nft-worker-asset {
          width: 20px;
          padding: 2px 0;
        }
        .nft-get-time {
          font-size: 13px;
          margin-left: 10px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
