<template>
  <div class="simulation-container">
    <van-button type="primary" size="small" @click="clickAdd"
      >添加NFT</van-button
    >
    <div class="nfts" v-for="(nft, index) of nfts" :key="nft.collection">
      <simulation-NFT :nft="nft" :index="index" @change="change" />
    </div>
  </div>
  <van-popup :show="show" round position="bottom">
    <van-picker
      title="标题"
      show-toolbar
      :columns="columns"
      @confirm="onConfirm"
      @cancel="onCancel"
    />
  </van-popup>
</template>

<script>
import { mapState } from "vuex";
import SimulationNFT from "../components/SimulationNFT.vue";
import dfsnft from "@/utils/dfsnft";

export default {
  name: "Simulation",
  components: { SimulationNFT },
  data() {
    return {
      nfts: [],
      show: false,
    };
  },
  watch: {},
  computed: {
    columns() {
      return this.shopConf.flatMap((v) => {
        return v.name;
      });
    },
    ...mapState({
      shopPngPre: (state) => state.app.shopPngPre,
      shopConf: (state) => state.app.shopConf,
      freecpu: (state) => state.app.freecpu,
    }),
  },
  async mounted() {
    await dfsnft.getWorker();
  },
  unmounted() {},
  methods: {
    change({ index, level, type, task }) {
      this.nfts[index].level = level;
      this.nfts[index].type = type;
      this.nfts[index].task = task;
      if (task) {
        var ca = task.create_asset;
        this.nfts[index].task.create_asset_24 =
          (
            ((60 * 60 * 24) / task.require_time) *
            parseFloat(ca) *
            level
          ).toFixed(4) +
          " " +
          task.key;
        var tra = [];
        for (let ra of task.require_asset) {
          if (ra.indexOf(" ") !== -1) {
            tra.push(
              (
                ((60 * 60 * 24) / task.require_time) *
                parseFloat(ra) *
                level
              ).toFixed(4) +
                " " +
                ra.split(" ")[1]
            );
          } else {
            tra.push(ra);
          }
        }
        this.nfts[index].task.require_asset_24 = tra;
      }
    },
    clickAdd() {
      this.show = true;
    },
    onConfirm(value) {
      this.show = false;
      this.nfts.push(
        this.shopConf
          .filter((v) => {
            return v.name === value;
          })
          .map((v) => {
            v.png = this.shopPngPre + v.collection + ".png";
            return v;
          })[0]
      );
    },
    onCancel() {
      this.show = false;
    },
    calc() {},
  },
};
</script>
<style lang="scss" scoped>
.simulation-container {
  width: 100%;
  .nfts {
    margin: 10px;
    border-top: 4px solid #eee;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    background-color: #fff;
    padding: 10px;
    padding-bottom: 0;
  }
}
</style>
